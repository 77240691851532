import { Box } from "@gocardless/flux-react";
import { ReactElement, forwardRef, useEffect, useState } from "react";
import { useLocalStorage } from "src/common/hooks/useLocalStorage/useLocalStorage";
import { isDisplayed } from "src/common/isInDom";

export enum ContentName {
  PricingUpdateBannerDismissed = "gc.is_pricing_update_banner_dismissed",
  InstalmentsBannerDismissed = "gc.is_instalments_banner_dismissed",
  QRCodeBannerDismissed = "gc.is_qrcode_banner_dismissed",
  InstalmentsBannerCustomerDetailsDismissed = "gc.is_instalments_banner_customer_details_dismissed",
  PlanSchedulePauseFeedbackBannerDismissed = "gc.is_plan_schedule_pause_feedback_banner_dismissed",
  PipeBannerDismissed = "gc.is_pipe_banner_dismissed",
  BadBannerDismissed = "gc.is_bad_banner_dismissed",
  BulkChangeEntryPointBannerDismissed = "gc.is_bulk_change_entry_point_banner_dismissed",
}

interface DismissibleContentProps {
  name: ContentName;
  renderContent: (dismissContent: () => void) => ReactElement;
  onContentMount?: () => void;
}

export const DismissibleContent = forwardRef<
  HTMLDivElement,
  DismissibleContentProps
>(({ name, renderContent, onContentMount }, ref) => {
  const isRefDefined = !!ref;
  //@ts-expect-error forwardRef is an union of the callback ref and ref objet, hence the error
  const nodeElement = ref?.current as HTMLElement;

  const [contentDismissed, setContentDismissed] = useLocalStorage(
    name,
    String(false)
  );
  const [showContent, setShowContent] = useState(
    !(contentDismissed === "true")
  );
  const dismissContent = () => {
    setShowContent(false);
    setContentDismissed("true");
  };

  useEffect(() => {
    if (showContent && (!isRefDefined || isDisplayed(nodeElement))) {
      onContentMount?.(); // fire onContentMount, provided it is being displayed (not hidden by the TopBanner component)
    }
  }, [showContent, onContentMount, isRefDefined, nodeElement]);

  return showContent ? (
    <Box ref={ref}>{renderContent(dismissContent)}</Box>
  ) : null;
});
