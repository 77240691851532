import { Fragment, createRef, useEffect, useRef, useState } from "react";
import { Box, useTheme } from "@gocardless/flux-react";

import { BadBanner } from "./BadBanner";
import { TwoFactorAuthenticationBanner } from "./TwoFactorAuthenticationBanner";
import { ForcedPasswordResetBanner } from "./ForcedPasswordResetBanner";
import { MFAEnforcementDelayedBanner } from "./MFAEnforcementDelayedBanner";
import { PricingUpdateBanner } from "./PricingUpdateBanner";
import { PipeBanner } from "./PipeBanner";

enum BannerPriority {
  High, // Highest priority, like legal terms or important updates
  Medium, // Medium priority, like pricing update or forced password reset
  Low, // Low priority, feature related banners
}

const MAX_BANNERS = 2;

const banners = [
  {
    priority: BannerPriority.High,
    component: MFAEnforcementDelayedBanner,
  },
  {
    priority: BannerPriority.High,
    component: ForcedPasswordResetBanner,
  },
  {
    priority: BannerPriority.Medium,
    component: TwoFactorAuthenticationBanner,
  },
  {
    priority: BannerPriority.Medium,
    component: PricingUpdateBanner,
  },
  {
    priority: BannerPriority.Medium,
    component: PipeBanner,
  },
  {
    priority: BannerPriority.Low,
    component: BadBanner,
  },
];

export const TopBanners: React.FC = () => {
  const { theme } = useTheme();
  const [isViisble, setIsVisible] = useState(false);
  const bannerRef = useRef<HTMLDivElement>(null);
  const elementsRef = useRef(
    banners.map(({ priority }) => ({
      element: createRef<HTMLDivElement>(),
      priority,
    }))
  );

  useEffect(() => {
    const observer = new ResizeObserver(() => {
      // Filter out null element and sort elements by ascending priority
      const sortedByPriority = elementsRef.current
        .filter((el) => el.element.current)
        .sort((a, b) => b.priority - a.priority);

      const totalBannerItems = sortedByPriority.length;
      const itemsToRemoveCount = Math.max(0, totalBannerItems - MAX_BANNERS); // Calculate the number of items to remove

      // Loop through the elements to remove up to the calculated count
      sortedByPriority.slice(0, itemsToRemoveCount).forEach((item) => {
        const element = item?.element.current;
        if (element instanceof HTMLElement) {
          // Check if the current element is an instance of HTMLElement
          element.style.display = "none";
        }
      });
    });

    if (bannerRef.current) {
      observer.observe(bannerRef.current);
      setIsVisible(true);
      return () => observer.disconnect();
    }

    return () => {};
  }, []);

  // managing visibility in the state allows for a first lifecycle render of banners without flickering of the order
  const visibility = isViisble ? "visible" : "hidden";
  return (
    <Box
      ref={bannerRef}
      css={{ gap: theme.spacing(1), visibility }}
      layout="flex"
      flexDirection="column"
    >
      {banners.map(({ component: Item }, idx) => (
        <Fragment key={idx.toString()}>
          <Item ref={elementsRef.current[idx]?.element} />
        </Fragment>
      ))}
    </Box>
  );
};
