import { FC, useCallback, useEffect, useRef, useState } from "react";
import { confetti } from "@tsparticles/confetti";
import {
  AlignItems,
  Box,
  Button,
  ButtonSize,
  ButtonVariant,
  Dialog,
  FontWeight,
  Glyph,
  Icon,
  Separator,
  Space,
  Text,
  TextAlign,
  TypePreset,
  VisuallyHidden,
} from "@gocardless/flux-react";
import { parseAsBoolean, useQueryState } from "nuqs";
import { AnimatePresence, motion } from "framer-motion";
import { ToTranslate } from "src/components/i18n";
import { OptimizelyFlag } from "src/components/third-parties/Optimizely/constants";
import { useOptimizelyVariation } from "src/components/third-parties/Optimizely/useOptimizelyVariation";
import { useVerificationStatus } from "src/common/hooks/useVerificationStatus";
import { CreditorsVerificationStatus } from "@gocardless/api/dashboard/types";
import { useSegment } from "src/components/third-parties/Segment/useSegment";
import { TrackingEvent } from "src/common/trackingEvents";
import { LroEligibilityResponse } from "src/common/trackingEventAttributes";
import { useCollectionsPermitted } from "src/components/routes/SetupPayments/common/hooks/useCollectionsPermitted";
import { useMediaQuery } from "src/common/hooks/useMediaQuery";
import { useActivationDiscount } from "src/components/routes/Setup/common/components/ActivationDiscount/useActivationDiscount";
// eslint-disable-next-line import/no-unresolved
import CollectionsEnabledDialogImage from "src/assets/svg/collections-enabled-dialog.svg";

import AnimatedLoadingText from "./AnimatedLoadingText";
import { OdometerAnimation } from "./OdometerAnimation";

// refresh creditor restriction status every 3s until undergoing_initial_onboarding_verifications is true
const CHECK_INTERVAL = 3000;

// close the dialog after 20s if the restriction does not become true
const CHECK_TIMEOUT = 20000;

export const RewardCollectionsEnabledDialog: FC = () => {
  const { isVariationOn } = useOptimizelyVariation({
    flag: OptimizelyFlag.TURBO_GROWTH_REWARDS_AND_BOOSTS,
  });
  const { sendEvent } = useSegment();
  const {
    mediaQueryList: { matches: prefersReducedMotion },
  } = useMediaQuery("(prefers-reduced-motion)");
  const [open, setOpen] = useQueryState(
    "reward_collections_enabled_dialog",
    parseAsBoolean
  );

  const [content, setContent] = useState<"loading" | "reward">("loading");

  const [intervalPassed, setIntervalPassed] = useState(false);

  const startTime = useRef(new Date().getTime());

  const {
    checkingLowRisk,
    refreshCollectionsPermitted,
    collectionsEnabledTrackingAttribute,
  } = useCollectionsPermitted();

  const sendDialogClosedEvent = useCallback(
    (reason: LroEligibilityResponse) => {
      const endTime = new Date().getTime();
      sendEvent(TrackingEvent.GET_STARTED_COLLECTIONS_ENABLED_DIALOG_CLOSED, {
        duration: (endTime - startTime.current) / 1000,
        reason,
        ...collectionsEnabledTrackingAttribute,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [collectionsEnabledTrackingAttribute]
  );

  const { status } = useVerificationStatus();
  const { remainingDays } = useActivationDiscount();

  useEffect(() => {
    if (status !== CreditorsVerificationStatus.Successful && isVariationOn) {
      setOpen(true);
      sendEvent(TrackingEvent.GET_STARTED_COLLECTIONS_ENABLED_DIALOG_OPENED);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVariationOn, setOpen, status]);

  useEffect(() => {
    if (!open) return;

    const interval = setInterval(() => {
      setIntervalPassed(true);
      refreshCollectionsPermitted();
    }, CHECK_INTERVAL);

    const timeout = setTimeout(() => {
      if (remainingDays) {
        setContent("reward");
      } else {
        handleContinueClick();
      }
      sendDialogClosedEvent(LroEligibilityResponse.TIMEOUT);
      clearInterval(interval);
    }, CHECK_TIMEOUT);

    return () => {
      clearInterval(interval);
      clearTimeout(timeout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, remainingDays]);

  useEffect(() => {
    if (!open || !intervalPassed) return;

    if (!checkingLowRisk) {
      if (remainingDays) {
        setContent("reward");
      } else {
        handleContinueClick();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, intervalPassed, checkingLowRisk, remainingDays]);

  const handleContinueClick = () => {
    setOpen(null);
    sendDialogClosedEvent(LroEligibilityResponse.SUCCESS);

    if (!prefersReducedMotion) {
      confetti({
        particleCount: 125,
        spread: 70,
        origin: { y: 0.6 },
      });
    }
  };

  const handleLearnMoreClick = () => {};

  return (
    <Dialog open={open ?? false} height="430px">
      <AnimatePresence exitBeforeEnter>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          key={content}
        >
          {content === "loading" ? (
            <Box
              layout="flex"
              flexDirection="column"
              alignItems={AlignItems.Center}
            >
              <img
                src={CollectionsEnabledDialogImage}
                width={192}
                height={170}
                alt=""
              />

              <Space v={1.5} />

              <Text
                preset={TypePreset.Body_02}
                weight={FontWeight.SemiBold}
                textAlign={TextAlign.Center}
              >
                <ToTranslate>
                  Time to say goodbye to late, manual payments
                </ToTranslate>
              </Text>

              <Separator spacing={[[1.5, 0]]} css={{ width: "100%" }} />

              <Box spaceBelow={0.25}>
                <Icon name={Glyph.Spinner} size="32px" />
              </Box>
              <AnimatedLoadingText />
            </Box>
          ) : null}

          {content === "reward" ? (
            <Box
              layout="flex"
              flexDirection="column"
              alignItems={AlignItems.Center}
              key="reward"
            >
              <OdometerAnimation
                startValue={1}
                endValue={remainingDays ?? 1}
                delay={1500}
              />

              <Space v={1} />
              <Text size={6} weight={FontWeight.SemiBold}>
                <ToTranslate>
                  <VisuallyHidden>{remainingDays}</VisuallyHidden>
                  Days without fees earned!
                </ToTranslate>
              </Text>

              <Separator spacing={[[2, 0]]} css={{ width: "100%" }} />

              <Button
                variant={ButtonVariant.PrimaryOnLight}
                onClick={handleContinueClick}
              >
                <ToTranslate>Continue</ToTranslate>
              </Button>

              <Space v={1} />

              <Button
                variant={ButtonVariant.TextOnLight}
                leftIcon={Glyph.InfoCircle}
                size={ButtonSize.Sm}
                onClick={handleLearnMoreClick}
              >
                <ToTranslate>Learn more</ToTranslate>
              </Button>
            </Box>
          ) : null}
        </motion.div>
      </AnimatePresence>
    </Dialog>
  );
};
